import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import StructuredData from '../components/structured-data'

function AboutPage() {
  return (
    <Layout>
      <SEO title="About Us" slug="/about" />
      <StructuredData pageType="webPage" slug="about" pageTitle="About Us" />
      <div className="container lg:max-w-4xl md:text-xl entry">
        <h1 className="text-3xl lg:text-5xl mb-6 lg:mb-10">About Us</h1>
        <p>
          Real Estate Shows is the leader in automated real estate video tours.
          We know there’s a lot of technology available when it comes to real
          estate video tours - from 3D to VR to Augmented Reality. Instead of
          living on the bleeding edge, our solution goes a different route -
          embracing what you care most about, your time and money.
        </p>
        <p>
          Until now, real estate agents and brokers that wanted video tours on
          their MLS listings, agent sites, and property pages, had to get
          involved. It doesn’t matter what you had to do, it shifted you from
          your core focus (serving your customer) to becoming a technologist.
          And we know you didn’t sign up for that.
        </p>
        <p>
          Our no-touch platform takes a property address and does everything for
          you. Everything.
        </p>
        <p>
          There’s never been a solution like Real Estate Shows. More
          importantly, there’s never been a solution that scales like our
          product. Give us one property, no problem. Give us 50,000, again, no
          problem.
        </p>
        <p>
          Until Real Estate Shows, there was no solution for the times we’re
          living in now - where every single property on every single MLS needs
          a video tour. But we’ve been building our product for such a day as
          this.
        </p>
        <p>
          We’ve helped more than 50,000 agents produce a Real Estate Show. We
          can help you too.
        </p>
      </div>
    </Layout>
  )
}

export default AboutPage
